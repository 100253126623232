









































































import { Component, Vue } from 'vue-property-decorator';
import LeaseList, { ILeaseList_Item } from '@/modules/Lease/List';
import DepotList, { IDepotList_Item } from '@/modules/Depot/List';
import User from '@/modules/User';

Component.registerHooks(['beforeRouteEnter']);

@Component
export default class Index extends Vue {
  isBusy        = true;
  canEditLeases = false;

  searchLeaseHeadQuery = '';
  searchContentsQuery  = '';

  perPage     = 10;
  currentPage = 1;

  depot  = null as number | null;
  depots = [] as { Text: string, Value: number | null }[];

  leaseList: LeaseList = new LeaseList();

  fields = [
    { key: 'LeaseNumber',     label: this.$t('Lease number'),                                                     },
    { key: 'CreatedAt',       label: this.$t('Created at'),                           class: 'display-priority-6' },
    { key: 'CustomerNumber',  label: this.$t('Custno'),                               class: 'display-priority-4' },
    { key: 'CustomerName',    label: this.$t('Customer'),                             class: 'display-priority-1' },
    { key: 'CustomerAccount', label: `${this.$t('Project')} / ${this.$t('Account')}`, class: 'display-priority-3' },
    { key: 'PostalArea',      label: this.$t('Postal area'),                          class: 'display-priority-5' },
    { key: 'CustomerType',    label: this.$t('Customer type'),                        class: 'display-priority-6' },
  ];

  leaseSelected(record: ILeaseList_Item, idx: number) {
    this.$router.push(`/Lease/${record.LeaseId}/Items`);
  }

  updatePropsFromQueryParams() {
    const { page, depot, search, search2 } = this.$route.query;

    if (page)    this.currentPage = +page || this.currentPage;
    if (depot)   this.depot = +depot || this.depot;
    if (search)  this.searchLeaseHeadQuery = search as string;
    if (search2) this.searchContentsQuery = search2 as string;
  }

  beforeRouteEnter(to: any, from: any, next: any) {
    next((vm: any) => { vm.updatePropsFromQueryParams(); });
  }

  updateQueryParams() {
    this.$router.replace({
      query: {
        ...this.$route.query,

        page: this.currentPage + '',
        search: this.searchLeaseHeadQuery ? this.searchLeaseHeadQuery : undefined,
        search2: this.searchContentsQuery ? this.searchContentsQuery : undefined,
        depot: this.depot ? this.depot + '' : undefined
      }
    });
  }

  resetPageAndReloadLeases() {
    this.currentPage = 1;
    this.updateQueryParams();
    this.fetchLeases();
  }

  pageSelected(page: number) {
    const nextPage = page.toString();

    if (this.$route.query.page !== nextPage)
      this.$router.replace({ query: { ...this.$route.query, page: nextPage } });

    this.currentPage = page;
    this.fetchLeases();
  }

  async fetchDepots() {
    this.isBusy = true;
    const depotList = await DepotList.FetchAll();
    const depotSelectOptions = depotList.Items.map((d: IDepotList_Item) => ({ Text: d.Name, Value: d.Id }));

    this.depots = [{ Text: this.$t('All depots') as string, Value: null as number | null }].concat(depotSelectOptions);

    if (!this.depots.find((d: { Text: string, Value: number | null }) => d.Value === this.depot)) {
      this.depot = null;
      this.updateQueryParams();
    }

    this.isBusy = false;
  }

  async fetchLeases() {
    this.isBusy = true;

    this.leaseList = await LeaseList.Fetch(this.currentPage, this.perPage, this.depot, this.searchLeaseHeadQuery, this.searchContentsQuery);

    this.isBusy = false;
  }

  async mounted() {
    await this.$nextTick();

    const userDepot = await User.Depot();
    if (!this.depot && userDepot)
      this.depot = userDepot.Id;

    await this.fetchDepots();
    await this.fetchLeases();
    
    this.canEditLeases = await User.Can('EditLeases');
  }
}
