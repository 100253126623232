









import { Component, Vue } from 'vue-property-decorator';
import LeaseEdit from '@/modules/Lease/Edit';

@Component
export default class Edit extends Vue {
  async onSubmit(leaseEdit: LeaseEdit) {
    await leaseEdit.Save();
    this.$router.push(`/Lease/${leaseEdit.Id}/Items`);
  }
}
