













































import {
  Watch,
  Prop,
  Component,
  Vue
} from 'vue-property-decorator';
import User from '@/modules/User';
import LeaseFileList, { ILeaseFileList_Item } from '@/modules/LeaseFile/List';

@Component
export default class Files extends Vue {
  canEditLeases = false;
  isBusy        = true;
  leaseFileList = new LeaseFileList();
  fields        = [] as Array < any > ;

  itemSelected(record: ILeaseFileList_Item, idx: number) {
    window.open(record.Url);
  }

  async fileSelected(e: Event) {
    this.isBusy = true;
    
    try {
      await LeaseFileList.Upload(this.leaseFileList.LeaseId, (e as any).target.files[0]);
      this.fetchLeaseFiles();
    } catch {
      (this as any).$bvModal.msgBoxOk(this.$t('Please check your internet connection and try again.') as string, {
          title: this.$t('File upload failed'),
          centered: true
        });
    } finally {
      (e.target as any).value = '';
      this.isBusy = false;
    }
  }

  async onDelete(item: ILeaseFileList_Item) {
    const isConfirmed = await (this as any).$bvModal.msgBoxConfirm(this.$t('Are you sure?') !, {
      title: `${this.$t('Delete')} ${item.Name}`,
      okVariant: 'danger',
      okTitle: this.$t('Yes'),
      cancelTitle: this.$t('Cancel'),
      hideHeaderClose: false,
      centered: true
    }).catch(() => {});

    if (isConfirmed) {
      await LeaseFileList.Delete(item.Id);
      this.fetchLeaseFiles();
    }
  }

  async fetchLeaseFiles() {
    this.isBusy = true;
    this.leaseFileList = await LeaseFileList.Find(+this.$route.params.leaseId);
    this.isBusy = false;
  }

  async constructTableFields() {
    const fields = [
      { key: 'Name', label: this.$t('Name') },
    ];

    if (this.canEditLeases) {
      fields.push({ key: 'Actions', label: '', tdClass: 'text-center', thStyle: 'width: 80px;' } as any);
    }
    
    this.fields = fields;
  }

  async mounted() {
    this.canEditLeases = await User.Can('EditLeases');

    await this.$nextTick();
    await this.constructTableFields();
    await this.fetchLeaseFiles();
  }
}
